<template>
  <div>
    <a-table
      bordered
      size="middle"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      :columns="columns"
      @change="tableChange"
      :dataSource="tableData"
      :loading="tableLoading"
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
      <span slot="action" slot-scope="text, record, index">
        <a-button
          shape="circle"
          icon="up"
          :disabled="index === 0"
          @click.stop="handleQuestionOrderChange(record, index, 'up')"
          size="small"
        />
        <a-divider type="vertical" />
        <a-button
          shape="circle"
          icon="down"
          :disabled="index === tableData.length - 1"
          @click.stop="handleQuestionOrderChange(record, index, 'down')"
          size="small"
        />
        <a-divider type="vertical" />
        <a @click="handleEdit(record.id)">编辑</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="handleDelete(record.id)">删除</a>
      </span>
    </a-table>
    <a-modal :width="550" :visible="addModal" onOk="保存" title="新增" @ok="handleAddOk" @cancel="closeAddModal">
      <ExtendForm
        ref="addRef"
        :systemModule="systemModule"
        :instanceId="instanceId"
        type="add"
        @addSuccess="addSuccess"
        @addError="addError"
      ></ExtendForm>
    </a-modal>
    <a-modal :width="550" title="修改" :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal">
      <ExtendForm
        ref="editRef"
        @editSuccess="editSuccess"
        @editError="editError"
        :systemModule="systemModule"
        :instanceId="instanceId"
      >
      </ExtendForm>
    </a-modal>
  </div>
</template>
<script>
import SERVICE_URLS from '@/api/service.url'
import { extendInfoColumns } from './common/common'
import entityCRUD from '@/views/common/mixins/entityCRUD'
import ExtendForm from './ExtendForm'
export default {
  name: 'ExtendInfoList',
  components: { ExtendForm },
  mixins: [entityCRUD],
  props: {
    systemModule: {
      type: String,
      required: true,
    },
    instanceId: {
      required: true,
    },
  },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.extendInfo,
      initColumns: extendInfoColumns(),
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        systemModule: this.systemModule,
        instanceId: this.instanceId,
      },
      extendListModal: false,
    }
  },
  watch: {
    instanceId(id) {
      this.searchParams.instanceId = id
      this.search()
    },
  },
  methods: {
    closeExtendListModal() {
      this.extendListModal = false
    },
    clickConfig() {
      this.extendListModal = true
    },
    handleQuestionOrderChange(record, index, type) {
      const sortList = []
      if (type === 'up') {
        const selectSortData = {
          id: record.id,
          sorter: this.tableData[index - 1].sorter,
        }
        sortList.push(selectSortData)
        const upRowSortData = {
          id: this.tableData[index - 1].id,
          sorter: record.sorter,
        }
        sortList.push(upRowSortData)
      } else {
        const selectSortData = {
          id: record.id,
          sorter: this.tableData[index + 1].sorter,
        }
        sortList.push(selectSortData)
        const upRowSortData = {
          id: this.tableData[index + 1].id,
          sorter: record.sorter,
        }
        sortList.push(upRowSortData)
      }
      this.$http(this, {
        url: SERVICE_URLS.extendInfo.update_sort,
        data: sortList,
        noTips: true,
        success: () => {
          this.search()
        },
      })
    },
  },
}
</script>
